<template>
  <div style="height: 100%; width: 100%;">
    <en-table-layout toolbar pagination :tableData="pageData2.data" :loading="loading" ref="layout">
      <template slot="toolbar">
        <el-form-item label="发票编号" prop="sn">
          <el-input clearable v-model="params.batch_sn" placeholder="请输入发票编号"></el-input>
        </el-form-item>
        <el-form-item label="申请时间" prop="mobile">
          <el-date-picker style="width: 220px" v-model="time_range" type="daterange" align="center" size="medium"
            :editable="false" value-format="timestamp" unlink-panels range-separator="-" start-placeholder="开始日期"
            end-placeholder="结束日期"></el-date-picker>
        </el-form-item>
        <el-form-item label="状态" prop="mobile">
          <el-select v-model="params.status" placeholder="请选择状态" clearable>
            <el-option v-for="(item, index) in statusList" :key="index" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="站点名称" prop="sn">
          <el-input placeholder="请输入站点名称进行搜索" clearable size="medium" v-model.trim="params.apply_name"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click.prevent="getEnterpriseAuditList" size="small" native-type="submit">
            搜索
          </el-button>
        </el-form-item>
      </template>

      <template slot="table-columns">
        <el-table-column label="发票编号" prop="batch_sn" />

        <el-table-column label="申请时间" prop="create_time" width="160">
          <template slot-scope="{row}">{{ row.create_time | unixToDate }}</template>
        </el-table-column>
        <el-table-column label="站点名称" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.apply_name }}
          </template>
        </el-table-column>
        <el-table-column label="发票金额" prop="receipt_amount" />
        <el-table-column label="状态" prop="status">
          <template slot-scope="{row}">
            <span>{{ statusList.find(item => item.value === row.status)?.label || '' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="提交人" prop="operator" />
        <el-table-column label="操作" width="180">
          <template slot-scope="{row}">
            <div>
              <el-button v-if="row.status === 0" size="mini" type="primary" @click="audit(row)">审核</el-button>
              <el-button v-if="row.status === 1" size="mini" type="primary" @click="mail(row)">邮寄</el-button>
              <el-button v-if="row.status === 2" size="mini" type="primary" @click="mailEdit(row)">修改邮寄</el-button>
              <el-button size="mini" type="primary" @click="detail(row)">查看</el-button>
            </div>
          </template>
        </el-table-column>
      </template>

      <el-pagination slot="pagination" @size-change="handlePageSizeChange2" @current-change="handlePageCurrentChange2"
        :current-page="pageData2.page_no" :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background
        :page-size="pageData2.page_size" :total="pageData2.data_total"></el-pagination>
    </en-table-layout>
    <x-dialog :proxy="auditProxy">
      <div style="padding: 20px 0;">
        <el-form>
          <el-form-item label="审核" label-width="120px">
            <el-radio-group v-model="auditFrom.pass">
              <el-radio :label="1">通过</el-radio>
              <el-radio :label="0">拒绝</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="审核备注" label-width="120px">
            <el-input type="textarea" v-model="auditFrom.reason" style="width: 60%;"
              :autosize="{ minRows: 4, maxRows: 4 }" placeholder="请输入备注信息，不超过250字" maxlength="250"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </x-dialog>
    <x-dialog :proxy="mailProxy">
      <div style="padding: 20px 0;">
        <el-form :model="mailFrom" :rules="rules" ref="mailFrom">
          <el-form-item label="选择物流" label-width="120px" prop="logi_id">
            <el-select v-model="mailFrom.logi_id" placeholder="请选择物流">
              <el-option v-for="item in logisticsData" :key="item.logi_id" :label="item.name" :value="item.logi_id" />
            </el-select>
          </el-form-item>
          <el-form-item label="物流单号" label-width="120px" prop="delivery_no">
            <el-input v-model="mailFrom.delivery_no" style="width: 60%;" :maxlength="50"></el-input>
          </el-form-item>
          <el-form-item label="收件人手机号" label-width="120px" prop="receive_phone">
            <el-input v-model="mailFrom.receive_phone" style="width: 60%;"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </x-dialog>
    <x-dialog :proxy="mailEditProxy">
      <div style="padding: 20px 0;">
        <el-form :model="mailEditFrom" :rules="rules" ref="mailEditFrom">
          <el-form-item label="选择物流" label-width="120px">
            <el-select v-model="mailEditFrom.logi_id" placeholder="请选择物流" prop="logi_id">
              <el-option v-for="item in logisticsData" :key="item.logi_id" :label="item.name" :value="item.logi_id" />
            </el-select>
          </el-form-item>
          <el-form-item label="物流单号" label-width="120px" prop="delivery_no">
            <el-input v-model="mailEditFrom.delivery_no" style="width: 60%;" :maxlength="50"></el-input>
          </el-form-item>
          <el-form-item label="收件人手机号" label-width="120px" prop="receive_phone">
            <el-input v-model="mailEditFrom.receive_phone" style="width: 60%;"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </x-dialog>
  </div>
</template>

<script>
import EnTableLayout from '../../../../ui-components/TableLayout/src/main';
import * as API_logistics from "@/api/expressCompany";
import { getEnterpriseAuditList, aduditHitoryAccount, postReceiptEnterprise, updateReceiptEnterprise, getReceiptEnterprise } from '@/api/enterpriseAudit';
import XDialog from '@/components/x-dialog/x-dialog.vue';
import { $xDialog } from '@/components/x-dialog/dialog.proxy';
export default {
  name: 'ManagerSellerEnterpriseAudit',
  components: { EnTableLayout, XDialog },
  data () {
    return {
      logisticsData: [],
      pageData2: { data: [] },
      loading: false,
      time_range: [],
      params: {
        page_no: 1,
        page_size: 20,
        type: 4,
        status: ''
      },
      statusList: [//审核状态
        { label: '全部', value: '' },
        { label: '待审核', value: 0 },
        { label: '已拒绝', value: -1 },
        { label: '已通过', value: 1 },
        { label: '已邮寄', value: 2 },

      ],
      auditFrom: {
        pass: 1,
        reason: ''
      },
      mailFrom: {
        logi_id: '', // 物流公司id
        logi_name: '', // 物流公司名称
        receive_phone: '', // 收件人手机号
        delivery_no: '', // 物流单号
      },
      mailEditFrom: {
        logi_id: '', // 物流公司id
        logi_name: '', // 物流公司名称
        receive_phone: '', // 收件人手机号
        delivery_no: '', // 物流单号
      },
      rules: {
        logi_id: [{ required: true, message: '请选择物流公司', trigger: 'change' }],
        receive_phone: [{ required: true, message: '请输入收件人手机号', trigger: 'blur' },
        { pattern: /^1[3-9]\d{9}$/, message: '请填写正确的手机号', }],
        delivery_no: [{ required: true, message: '请输入物流单号', trigger: 'blur' }],
      },
      auditProxy: $xDialog.create({
        title: '充值审核',
        width: "500px",
        afterDismiss: () => { },
        beforeConfirm: this.aduditHitoryAccount,
      }),
      mailProxy: $xDialog.create({
        title: '发票邮寄',
        width: "500px",
        afterDismiss: () => { },
        beforeConfirm: this.postReceiptEnterprise,
      }),
      mailEditProxy: $xDialog.create({
        title: '修改发票邮寄',
        width: "500px",
        afterDismiss: () => { },
        beforeConfirm: this.updateReceiptEnterprise,
      }),
    };
  },

  mounted () {
    this.$refs.layout.parentElement = this.parentElement
    this.getEnterpriseAuditList()
    this.getLogisticsCompanies()
  },

  methods: {
    async getEnterpriseAuditList () {
      if (this.time_range && this.time_range.length) {
        this.params.start_time = this.time_range[0] / 1000;
        this.params.end_time = this.time_range[1] / 1000;
      } else {
        this.params.start_time = void 0
        this.params.end_time = void 0
      }
      const res = await getEnterpriseAuditList(this.params);
      this.pageData2 = Object.freeze(res)
    },
    /** 获取物流公司信息列表 */
    async getLogisticsCompanies () {
      const res = await API_logistics.getExpressCompanyList({})
      this.logisticsData = res;
    },
    /**充值审核 */
    async aduditHitoryAccount () {
      const res = await aduditHitoryAccount(this.auditFrom)
      this.getEnterpriseAuditList()
    },
    /** 企业邮寄发票*/
    async postReceiptEnterprise () {
      const valid = await this.$refs.mailFrom.validate();
      if (!valid) return
      this.mailFrom.logi_name = this.logisticsData.find(item => item.logi_id === this.mailFrom.logi_id).name
      const res = await postReceiptEnterprise(this.mailFrom)
      this.$message.success('发票邮寄提交成功')
      this.getEnterpriseAuditList()
    },
    /**修改企业邮寄发票 */
    async updateReceiptEnterprise () {
      const valid = await this.$refs.mailEditFrom.validate();
      if (!valid) return
      this.mailEditFrom.logi_name = this.logisticsData.find(item => item.logi_id === this.mailEditFrom.logi_id).name
      const res = await updateReceiptEnterprise(this.mailEditFrom)
      this.$message.success('发票邮寄提交成功')
      this.getEnterpriseAuditList()
    },
    /**获取企业邮寄发票 */
    async getReceiptEnterprise (history_id) {
      const res = await getReceiptEnterprise({ history_id })
      this.mailEditFrom = res
    },
    audit (row) {
      this.auditFrom.history_id = row.history_id
      this.auditProxy.display()
    },
    mail (row) {
      this.mailFrom.history_id = row.history_id
      this.mailProxy.display()
    },
    async mailEdit (row) {
      this.mailEditFrom.history_id = row.history_id
      await this.getReceiptEnterprise(row.history_id)
      this.mailEditProxy.display()
    },
    detail (row) {
      this.$store.dispatch("addVisitedViews", this.$route);
      this.$router.push({
        name: 'financeInvoicemangerjiludetail',
        query: {
          id: row.history_id,
          is: 4
        }
      });
    },
    /** 分页大小发生改变 */
    handlePageSizeChange2 (size) {
      this.params.page_size = size;
      this.getEnterpriseAuditList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange2 (page) {
      this.params.page_no = page;
      this.getEnterpriseAuditList();
    },
  },
};
</script>

<style lang="scss" scoped></style>