
/**
 * 发票审核相关api
 */
import request from '@/utils/request'

/**
 * 获取列表
 * @param {*} params 
 * @returns 
 */
export const getEnterpriseAuditList = params => request({
  url: '/seller/shops/shopReceiptHistory/getEnterpriseAuditList',
  method: 'get',
  params
})

/**
 * 企业端审核发票(账单发票)
 * @param {*} params 
 * @returns 
 */
export const aduditHitoryAccount = params => request({
  url: '/seller/shops/shopReceiptHistory/aduditHitoryAccount',
  method: 'put',
  params
})

/**
 * 企业邮寄发票
 * @param {*} params 
 * @returns 
 */
export const postReceiptEnterprise = params => request({
  url: '/seller/shops/shopReceiptHistory/postReceiptEnterprise/' + params.history_id,
  method: 'put',
   params
})
  
/**
 * 修改企业邮寄发票
 * @param {*} params 
 * @returns 
 */
export const updateReceiptEnterprise = params => request({
  url: '/seller/shops/shopReceiptHistory/updateReceiptEnterprise/' + params.history_id,
  method: 'put',
  params
})
  
/**
 * 获取企业邮寄发票
 * @param {*} params 
 * @returns 
 */
export const getReceiptEnterprise = params => request({
  url: '/seller/shops/shopReceiptHistory/getReceiptEnterprise/' + params.history_id,
  method: 'get',
  params
  })